.App-header {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  min-height: 10vh;
  padding: 13px 0;
}
.header_img_container {
  width: 100%;
  margin: auto;
  padding: auto;
}

.header_img_container > img {
  width: 100%;
  max-width: 300px;
  height: auto;
}
