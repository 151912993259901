.notification-container {
  position: fixed;
  width: 100px;
  bottom: 65px;
  right: 0;
  margin: 20px 0px;
  z-index: 999;
}

.notification-container:hover .floating-notification {
  background: var(--prep-done-or-not-available-color);
}

.notification-container .floating-notification {
  color: #fff;
  position: absolute;
  width: 50px;
  height: 50px;
  background: #2cb3f0;
  bottom: 0;
  border: none;
  border-radius: 50%;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 999;
  cursor: pointer;
  transition: all 0.3s;
  background-color: var(--prep-blue-background-color);
}

.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.notification-badge {
  position: absolute;
  top: -8px;
  right: -8px;
  background-color: red;
  color: white;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.notification-box {
  background-color: white;
  position: absolute;
  top: -250px;
  right: 10px;
  width: 300px;
  height: 265px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  z-index: 998;
  opacity: 0;
  transform: translateY(-100%);
  transition: opacity 0.3s, transform 0.3s;
  border-radius: 5px;
  padding: 1.5rem 1rem;
  overflow: hidden;
}

.notification-box.active {
  opacity: 1; /* Mostrar */
  transform: translateY(0); /* Volver a la posición normal */
}

.container-body {
  overflow: auto;
  height: 100%;
}

.notification-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #eee;
  margin-bottom: 1rem;
}

.notification-item-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.notification-item-body {
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin-top: 0.3rem;
  text-align: start;
  word-break: break-word;
}

.chip {
  background-color: var(--prep-blue-background-color);
  color: white;
  padding: 0.1rem 0.8rem;
  border-radius: 0.8rem;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.3s;
}

.empty-notification {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  opacity: 0.5;
  font-size: 100px;
}
