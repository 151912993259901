.flex-position-footer {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
}

.flex-position-footer > button {
  width: max-content;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 1rem;
  overflow: hidden;
}
