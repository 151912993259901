.floating-container {
  position: fixed;
  width: 100px;
  bottom: 0;
  right: 0;
  margin: 20px 0px;
  z-index: 999;
}

.floating-container:hover {
  height: 300px;
}

.floating-container:hover .floating-button {
  background: var(--prep-done-or-not-available-color);
}

.floating-container .floating-button {
  color: #fff;
  position: absolute;
  width: 50px;
  height: 50px;
  background: #2cb3f0;
  bottom: 0;
  border: none;
  border-radius: 50%;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 999;
  cursor: pointer;
  transition: all 0.3s;
  background-color: var(--prep-blue-background-color);
}

.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
