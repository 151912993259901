.flex-position-footer {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
}

.flex-position-footer > button {
  width: max-content;
}

#addressTextArea {
  white-space: pre;
}

.textarea {
  background-color: #dddddd;
  color: #666666;
  padding: 1em;
  border-radius: 10px;
  border: 2px solid transparent;
  outline: none;
  font-family: "Heebo", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
  transition: all 0.2s;
}

.textarea:hover {
  cursor: pointer;
  background-color: #eeeeee;
}

.textarea:focus {
  cursor: text;
  color: #333333;
  background-color: white;
  border-color: #333333;
}

.grid-container {
  display: grid;
  grid-template-columns: 1.5fr;
  grid-template-rows: 1fr;
  gap: 1rem;
  grid-auto-flow: row;
  grid-template-areas: "fields";
}

.fields {
  grid-area: fields;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-template-rows: 1fr;
  gap: 1rem;
  grid-auto-flow: row;
}

@media screen and (max-width: 321px) {
  .grid-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    gap: 1rem;
    grid-auto-flow: row;
  }

  .fields {
    grid-area: fields;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(95px, 1fr));
    grid-template-rows: 1fr;
    grid-gap: 1rem;
    gap: 1rem;
    grid-auto-flow: row;
  }
}

@media (min-width: 322px) and (max-width: 420px) {
  .grid-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    gap: 1rem;
    grid-auto-flow: row;
  }

  .fields {
    grid-area: fields;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    grid-template-rows: 1fr;
    grid-gap: 1rem;
    gap: 1rem;
    grid-auto-flow: row;
  }
}

@media (min-width: 421px) and (max-width: 991px) {
  .grid-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    gap: 1rem;
    grid-auto-flow: row;
  }

  .fields {
    grid-area: fields;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-template-rows: 1fr;
    grid-gap: 1rem;
    gap: 1rem;
    grid-auto-flow: row;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .fields {
    display: flex;
    gap: 1rem;
  }
}
