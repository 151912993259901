:root {
  --prep-red-background-color: #ff0000;
  --prep-blue-background-color: #002e5b;
  --prep-secondary-background-color: #e7e8e9;
  --prep-hover-secondary-color: #d6d7d8;
  --prep-done-or-not-available-color: #607799;
  --prep-white-color: #ffffff;
  --prep-black-color: #000000;
  overflow: auto;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

/* buttons */
.available_button,
.not_available_button,
.delete-button {
  color: var(--prep-white-color);
  border: none;
  outline: none;
  padding: 0.3rem 1.2rem;
  border-radius: 5px;
  color: white;
  width: max-content;
}

.available_button {
  background-color: var(--prep-blue-background-color);
}

.not_available_button {
  background-color: var(--prep-done-or-not-available-color);
}

.delete-button {
  background-color: var(--prep-red-background-color);
}

.secondary-button {
  color: var(--prep-black-color);
  background-color: var(--prep-secondary-background-color);
  border: none;
  outline: none;
  padding: 8px;
  border-radius: 5px;
}

.secondary-button:hover,
.error-overlay-button:hover {
  background-color: var(--prep-hover-secondary-color);
}

.hov_only_color:hover {
  background: var(--prep-done-or-not-available-color);
}

/* Flex and grid */
.flex_row {
  display: flex;
  width: 100%;
}

.flex_row_with_gap {
  display: flex;
  gap: 1rem;
  width: 100%;
}

.responsive_grid_container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 1rem;
}

.responsive_grid_container_200_phone {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 1rem;
}

.box_shadow {
  padding: 1rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.buttons_end {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  margin: 1rem 0.6rem;
}

/* Bootstrap or library */
.form-control {
  text-align: center;
}

.fVmkgJ {
  padding: 0 !important;
}

/* Error Boundary */
iframe {
  display: none;
}

.error-overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 30vh;
  width: 50vh;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background-color: whitesmoke;
  color: black;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border-radius: 5px;
  text-align: center;
}

.error-overlay-button {
  color: var(--prep-black-color);
  background-color: var(--prep-secondary-background-color);
  border: none;
  outline: none;
  padding: 8px;
  border-radius: 5px;
  width: max-content;
}

.react-responsive-modal-closeButton {
  display: none !important;
}

/* other global styles */
.custom_style_select {
  border-radius: 5px;
  padding: 5px;
  margin-top: 7px;
}

.modal-content {
  line-height: 1.4;
  background: #f1f1f1;
  padding: 14px 28px;
  border-radius: 3px;
  max-width: 600px;
  min-width: 300px;
}

#accordionExample {
  display: none;
}

.divider {
  border-top: 3px solid #ececec;
  margin-bottom: 1rem;
}

.address_column {
  white-space: normal;
}

.btn_create_item[disabled] {
  opacity: 0.5;
}

/* Media querys for mobile */
@media screen and (max-width: 500px) {
  .form_input_separator {
    flex-wrap: wrap;
  }

  .buttons_end {
    flex-wrap: wrap;
  }

  .responsive_grid_container_200_phone {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }

  .buttons_end > button {
    width: 100%;
  }

  .react-responsive-modal-modal {
    max-width: 320px !important;
  }

  .controls_container {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }

  #content > img {
    max-width: 255px;
  }

  #accordionExample {
    display: block;
  }

  #pickupRetTable {
    display: none;
  }

  .container_gallery_controls {
    flex-wrap: wrap;
    gap: 0rem;
    margin-top: 0.8rem;
  }

  .camera_controls_style {
    margin: 0 !important;
  }

  .camera_gallery_controls {
    width: 100% !important;
    height: 100% !important;
  }

  .camera_dynamic_width {
    margin-bottom: 0.8rem;
  }

  .form_input_separator {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)) !important;
    gap: 1rem;
  }

  .parent_header {
    flex-wrap: wrap;
  }

  .custom_width_parent_button {
    max-width: none !important;
  }

  .parents_accordions {
    overflow-x: scroll !important;
  }
}

@media screen and (max-width: 767px) {
  .card-body {
    display: flex;
    flex-direction: column;
  }
  section:nth-child(1) {
    width: 100%;
  }
  section:nth-child(2) {
    width: 100%;
  }
}

@media screen and (max-width: 1023px) {
  .flex_wrap_mobile {
    flex-wrap: wrap;
  }
}
