video {
  display: block;
}

.upload-container {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.upload-container label {
  display: block;
  cursor: pointer;
}

.opening_camera_buttons {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
  width: 100%;
  margin-bottom: 15px;
  transition: all 0.5s ease;
  outline: none;
  border: none;
  padding: 0.3rem;
}

.upload-container:hover,
.opening_camera_buttons:hover {
  background-color: var(--prep-secondary-background-color);
  color: black;
}

#file-input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
}

.card-body {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row !important;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

#camera-select {
  border: none;
  border-bottom: 1px solid lightgray;
  outline: none;
  cursor: pointer;
}

.camera_controls_style {
  border: none;
  padding: 0.3rem 1rem;
  width: 100%;
  background-color: var(--prep-blue-background-color);
  color: white;
  border-radius: 5px;
  margin: 1rem auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.2s ease-in-out;
}

.container_gallery_controls {
  display: flex;
  gap: 0.5rem;
}

.camera_controls_style:hover {
  background-color: var(--prep-secondary-background-color);
  color: black;
}

.delete_button {
  background-color: var(--prep-red-background-color);
}

.camera_dynamic_width {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.camera_gallery_controls {
  width: 350px;
  height: 300px;
}
