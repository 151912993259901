.accordion-button.collapsed {
  border-color: #ced4da;
}

.childs_container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 1rem;
}

.parents_container {
  margin: 1rem;
  padding: 1rem;
}

.no-data-message {
  display: flex;
  gap: 1rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f2f2f2;
  padding: 20px;
  border-radius: 4px;
  opacity: 0.9;
}

.emoji {
  font-size: 60px;
}

.parents_accordions {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 1rem;
  height: 20vh;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 1rem;
}

.accordion_button {
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding: 0.2rem 0.4rem;
  cursor: pointer;
  width: 100%;
  margin-top: 1rem;
  border-radius: 0.5rem;
  transition: 1s all;
}

.accordion_button:hover {
  background-color: #f2f2f2;
}

.parent_header {
  display: flex;
  gap: 1rem;
  align-items: center;
  padding: 0 1rem;
}

.custom_width_parent_button {
  max-width: max-content;
}
