.container_time_tracking {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
}

.form_input_separator {
  display: flex;
  gap: 0.5rem;
}

.form_input_separator > div {
  width: 100%;
}

.full-width {
  width: 100%;
}

.customModalTimeTracking {
  max-width: 700px;
  width: 100%;
}
