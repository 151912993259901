.header_modal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #e7e8e9;
}

.header_modal > button {
  width: max-content;
  all: unset;
}

.btn_scanner {
  background-color: var(--prep-blue-background-color);
  color: var(--prep-white-color);
  border: none;
  outline: none;
  padding: 4px 10px;
  border-radius: 5px;
  color: white;
  width: max-content;
  margin: 0 10px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

#scanner-container {
  position: relative;
}

#scanner-container > video {
  width: 100%;
  height: 100%;
}

#scanner-container > canvas.drawing,
canvas.drawingBuffer {
  position: absolute;
  left: 0;
  top: 0;
}
