@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.base-margin {
  margin: 1rem;
}

.form-control {
  text-align: start !important;
}

.rental-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.rental-header > div {
  display: flex;
  gap: 1rem;
}

.rental-header > div > button {
  width: max-content;
}

.title {
  text-align: start;
  font-family: "Poppins", sans-serif;
}

.rental-container {
  text-align: start;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 1rem;
}

.rental-container > section {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  padding: 20px;
}

.table-container {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.banner {
  background-color: #e7e8e9;
  padding: 10px;
  font-weight: bold;
}

.delivery-btn:hover,
.go-back-btn:hover {
  background-color: #d6d7d8;
}

.modal-header {
  border-bottom: 1px solid black !important;
}

.modal-body {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.modal-content {
  max-width: 100% !important;
}

.flex-body {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.flex-body > button {
  width: 36%;
}

.go-back-btn {
  background-color: #e7e8e9;
  border: none;
  outline: none;
  padding: 8px;
  border-radius: 5px;
  color: black;
  font-weight: bold;
}

.save-button-section {
  display: flex;
  gap: 1rem;
}

.save-button-section > button {
  width: 100%;
}

.flex-delivery-create {
  width: 100%;
  display: flex;
  gap: 0.5rem;
}

.section-select-1 {
  width: 80%;
}

.section-select-2 {
  width: 20%;
}

.flex-delivery-create > button {
  width: max-content;
}

.flex-delivery-create > button:hover {
  background-color: #d6d7d8;
}

.change-align {
  position: absolute;
  bottom: 0;
}

.error {
  color: red;
  font-size: 12px;
  font-weight: 600;
  display: none;
}

.full-width-field > select {
  width: 100%;
}

.flex-datetime-row {
  display: flex;
  gap: 0.5rem;
  width: 100%;
}

.flex-datetime-row > div {
  width: 49%;
}

.custom-size {
  width: 100%;
  overflow: hidden;
}

.signature-container {
  width: 100%;
}

.renLocationSelect {
  width: 100%;
}

@media screen and (max-width: 992px) {
  .flex-body {
    flex-wrap: wrap;
  }

  .flex-body > button {
    width: 100%;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1280px) {
  .rental-container {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
}
