#signatureName {
  background: powderblue;
}

.color-separate {
  background-color: #d9dfeb;
}

#showFiles,
#showCamera {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
  width: 100%;
  margin-bottom: 15px;
  transition: all 0.5s ease;
}

.hover_camera_btn:hover {
  background-color: lightcyan;
}
