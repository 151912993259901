@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.main-container {
  font-family: "Poppins", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background-image: url("./images/abstract.jpg");
  background-size: cover;
}

.user-info {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: whitesmoke;
  border: 1px solid #e0e0e0;
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
  padding: 80px 100px;
  max-width: 500px;
  height: 450px;
}

.logo-section {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  max-width: 500px;
  height: 450px;
  padding: 0 100px;
}

.logo-section > img {
  width: 100%;
  height: 65%;
}

.input-style {
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 10px;
  outline: none;
  margin-bottom: 10px;
}

.input-style:focus {
  border: 1px solid #000;
}

.btn-style {
  border: none;
  border-radius: 5px;
  padding: 10px;
  background-color: #607799;
  color: #fff;
  cursor: pointer;
  outline: none;
  transition: all 0.6s ease-in-out;
}

.btn-style:hover {
  background-color: #000;
}

.header {
  display: flex;
  flex-direction: column;
}

.header > * {
  text-align: center;
}

.mobile-logo {
  display: none;
}

.form-error {
  display: flex;
  flex-direction: column;
}

.error {
  color: red;
  font-size: 12px;
  font-weight: 600;
  display: none;
}

/* spinner button */
.spinner {
  content: "";
  display: none;
  width: 1.1rem;
  height: 1.1rem;
  border-radius: 50%;
  border: 3px solid #fff;
  border-top-color: #000;
  animation: spin 0.6s linear infinite;
  margin-top: 5px;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Custom Snackbar */
#snackbar {
  visibility: hidden;
  min-width: 250px;
  color: #fff;
  text-align: center;
  border-radius: 10px;
  padding: 16px;
  position: fixed;
  z-index: 1;
  bottom: 30px;
}

.show-bar {
  visibility: visible !important;
  animation: fadein 0.5s, fadeout 0.5s 4.5s;
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

/* Media query for phones 320 to 480px*/
@media screen and (max-width: 480px) {
  .user-info {
    height: 100vh;
    width: 100%;
    border-radius: 10px;
    padding: 80px 50px;
    background-color: white;
    gap: 0.5rem;
  }

  .logo-section {
    display: none;
  }

  .mobile-logo {
    display: block;
    width: max-content;
    height: 30%;
    margin: 0 auto;
  }
}

/* media query for tablet and less.. */
@media screen and (min-width: 481px) and (max-width: 768px) {
  .user-info {
    border-radius: 10px;
  }
  .logo-section {
    display: none;
  }
}

@media screen and (min-width: 769px) and (max-width: 875px) {
  .logo-section {
    padding: 80px 30px;
  }
  .logo-section > img {
    height: 100%;
  }
}
