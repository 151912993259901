.container_grid_info_opp {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 1rem;
  padding: 1rem;
}

.title_banner {
  background-color: #f5f5f5;
  border-radius: 5px;
  margin-bottom: 10px;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 0px 5px 0px #e0e0e0;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
  font-weight: 600;
  color: #000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.flex_info_opp {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
