.custom-modal {
  max-width: 1000px;
  width: 90%;
}

.container_inputs_parent {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 1rem;
}

.container_flex_inputs {
  display: flex;
  gap: 1rem;
}

.btn_create_item {
  border-radius: 6px;
  padding: 5px;
  color: white;
  background-color: var(--prep-blue-background-color);
  border: none;
  outline: none;
}

@media screen and (max-width: 768px) {
  /* Estilos específicos para pantallas más pequeñas */
  .custom-modal {
    max-width: 90%; /* Disminuye el ancho máximo en pantallas más pequeñas */
  }

  .container_flex_inputs {
    flex-wrap: wrap;
  }
}
