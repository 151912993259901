@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700);
:root {
  --prep-red-background-color: #ff0000;
  --prep-blue-background-color: #002e5b;
  --prep-secondary-background-color: #e7e8e9;
  --prep-hover-secondary-color: #d6d7d8;
  --prep-done-or-not-available-color: #607799;
  --prep-white-color: #ffffff;
  --prep-black-color: #000000;
  overflow: auto;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

/* buttons */
.available_button,
.not_available_button,
.delete-button {
  color: #ffffff;
  color: var(--prep-white-color);
  border: none;
  outline: none;
  padding: 0.3rem 1.2rem;
  border-radius: 5px;
  color: white;
  width: -webkit-max-content;
  width: max-content;
}

.available_button {
  background-color: #002e5b;
  background-color: var(--prep-blue-background-color);
}

.not_available_button {
  background-color: #607799;
  background-color: var(--prep-done-or-not-available-color);
}

.delete-button {
  background-color: #ff0000;
  background-color: var(--prep-red-background-color);
}

.secondary-button {
  color: #000000;
  color: var(--prep-black-color);
  background-color: #e7e8e9;
  background-color: var(--prep-secondary-background-color);
  border: none;
  outline: none;
  padding: 8px;
  border-radius: 5px;
}

.secondary-button:hover,
.error-overlay-button:hover {
  background-color: #d6d7d8;
  background-color: var(--prep-hover-secondary-color);
}

.hov_only_color:hover {
  background: #607799;
  background: var(--prep-done-or-not-available-color);
}

/* Flex and grid */
.flex_row {
  display: flex;
  width: 100%;
}

.flex_row_with_gap {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  width: 100%;
}

.responsive_grid_container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 1rem;
}

.responsive_grid_container_200_phone {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 1rem;
}

.box_shadow {
  padding: 1rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.buttons_end {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  justify-content: flex-end;
  margin: 1rem 0.6rem;
}

/* Bootstrap or library */
.form-control {
  text-align: center;
}

.fVmkgJ {
  padding: 0 !important;
}

/* Error Boundary */
iframe {
  display: none;
}

.error-overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 30vh;
  width: 50vh;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background-color: whitesmoke;
  color: black;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border-radius: 5px;
  text-align: center;
}

.error-overlay-button {
  color: #000000;
  color: var(--prep-black-color);
  background-color: #e7e8e9;
  background-color: var(--prep-secondary-background-color);
  border: none;
  outline: none;
  padding: 8px;
  border-radius: 5px;
  width: -webkit-max-content;
  width: max-content;
}

.react-responsive-modal-closeButton {
  display: none !important;
}

/* other global styles */
.custom_style_select {
  border-radius: 5px;
  padding: 5px;
  margin-top: 7px;
}

.modal-content {
  line-height: 1.4;
  background: #f1f1f1;
  padding: 14px 28px;
  border-radius: 3px;
  max-width: 600px;
  min-width: 300px;
}

#accordionExample {
  display: none;
}

.divider {
  border-top: 3px solid #ececec;
  margin-bottom: 1rem;
}

.address_column {
  white-space: normal;
}

.btn_create_item[disabled] {
  opacity: 0.5;
}

/* Media querys for mobile */
@media screen and (max-width: 500px) {
  .form_input_separator {
    flex-wrap: wrap;
  }

  .buttons_end {
    flex-wrap: wrap;
  }

  .responsive_grid_container_200_phone {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }

  .buttons_end > button {
    width: 100%;
  }

  .react-responsive-modal-modal {
    max-width: 320px !important;
  }

  .controls_container {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }

  #content > img {
    max-width: 255px;
  }

  #accordionExample {
    display: block;
  }

  #pickupRetTable {
    display: none;
  }

  .container_gallery_controls {
    flex-wrap: wrap;
    grid-gap: 0rem;
    gap: 0rem;
    margin-top: 0.8rem;
  }

  .camera_controls_style {
    margin: 0 !important;
  }

  .camera_gallery_controls {
    width: 100% !important;
    height: 100% !important;
  }

  .camera_dynamic_width {
    margin-bottom: 0.8rem;
  }

  .form_input_separator {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)) !important;
    grid-gap: 1rem;
    gap: 1rem;
  }

  .parent_header {
    flex-wrap: wrap;
  }

  .custom_width_parent_button {
    max-width: none !important;
  }

  .parents_accordions {
    overflow-x: scroll !important;
  }
}

@media screen and (max-width: 767px) {
  .card-body {
    display: flex;
    flex-direction: column;
  }
  section:nth-child(1) {
    width: 100%;
  }
  section:nth-child(2) {
    width: 100%;
  }
}

@media screen and (max-width: 1023px) {
  .flex_wrap_mobile {
    flex-wrap: wrap;
  }
}

#mosaicDiv {
  margin: 15px;
  padding: 16px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  vertical-align: top;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}

#mosaicDiv:hover {
  background-color: #f5f5f5;
}

#mosaicDiv > h1 {
  color: #000;
}

.img_container {
  margin: auto;
  max-width: 70px;
}

.img_container img {
  width: 100%;
  height: auto;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.main-container {
  font-family: "Poppins", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background-image: url(/static/media/abstract.f740d7bd.jpg);
  background-size: cover;
}

.user-info {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
  background-color: whitesmoke;
  border: 1px solid #e0e0e0;
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
  padding: 80px 100px;
  max-width: 500px;
  height: 450px;
}

.logo-section {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  max-width: 500px;
  height: 450px;
  padding: 0 100px;
}

.logo-section > img {
  width: 100%;
  height: 65%;
}

.input-style {
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 10px;
  outline: none;
  margin-bottom: 10px;
}

.input-style:focus {
  border: 1px solid #000;
}

.btn-style {
  border: none;
  border-radius: 5px;
  padding: 10px;
  background-color: #607799;
  color: #fff;
  cursor: pointer;
  outline: none;
  transition: all 0.6s ease-in-out;
}

.btn-style:hover {
  background-color: #000;
}

.header {
  display: flex;
  flex-direction: column;
}

.header > * {
  text-align: center;
}

.mobile-logo {
  display: none;
}

.form-error {
  display: flex;
  flex-direction: column;
}

.error {
  color: red;
  font-size: 12px;
  font-weight: 600;
  display: none;
}

/* spinner button */
.spinner {
  content: "";
  display: none;
  width: 1.1rem;
  height: 1.1rem;
  border-radius: 50%;
  border: 3px solid #fff;
  border-top-color: #000;
  -webkit-animation: spin 0.6s linear infinite;
          animation: spin 0.6s linear infinite;
  margin-top: 5px;
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/* Custom Snackbar */
#snackbar {
  visibility: hidden;
  min-width: 250px;
  color: #fff;
  text-align: center;
  border-radius: 10px;
  padding: 16px;
  position: fixed;
  z-index: 1;
  bottom: 30px;
}

.show-bar {
  visibility: visible !important;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 4.5s;
          animation: fadein 0.5s, fadeout 0.5s 4.5s;
}

@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

/* Media query for phones 320 to 480px*/
@media screen and (max-width: 480px) {
  .user-info {
    height: 100vh;
    width: 100%;
    border-radius: 10px;
    padding: 80px 50px;
    background-color: white;
    grid-gap: 0.5rem;
    gap: 0.5rem;
  }

  .logo-section {
    display: none;
  }

  .mobile-logo {
    display: block;
    width: -webkit-max-content;
    width: max-content;
    height: 30%;
    margin: 0 auto;
  }
}

/* media query for tablet and less.. */
@media screen and (min-width: 481px) and (max-width: 768px) {
  .user-info {
    border-radius: 10px;
  }
  .logo-section {
    display: none;
  }
}

@media screen and (min-width: 769px) and (max-width: 875px) {
  .logo-section {
    padding: 80px 30px;
  }
  .logo-section > img {
    height: 100%;
  }
}

.App-header {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  min-height: 10vh;
  padding: 13px 0;
}
.header_img_container {
  width: 100%;
  margin: auto;
  padding: auto;
}

.header_img_container > img {
  width: 100%;
  max-width: 300px;
  height: auto;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.base-margin {
  margin: 1rem;
}

.form-control {
  text-align: start !important;
}

.rental-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
}

.rental-header > div {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
}

.rental-header > div > button {
  width: -webkit-max-content;
  width: max-content;
}

.title {
  text-align: start;
  font-family: "Poppins", sans-serif;
}

.rental-container {
  text-align: start;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 1rem;
}

.rental-container > section {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  padding: 20px;
}

.table-container {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.banner {
  background-color: #e7e8e9;
  padding: 10px;
  font-weight: bold;
}

.delivery-btn:hover,
.go-back-btn:hover {
  background-color: #d6d7d8;
}

.modal-header {
  border-bottom: 1px solid black !important;
}

.modal-body {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
}

.modal-content {
  max-width: 100% !important;
}

.flex-body {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
}

.flex-body > button {
  width: 36%;
}

.go-back-btn {
  background-color: #e7e8e9;
  border: none;
  outline: none;
  padding: 8px;
  border-radius: 5px;
  color: black;
  font-weight: bold;
}

.save-button-section {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
}

.save-button-section > button {
  width: 100%;
}

.flex-delivery-create {
  width: 100%;
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.section-select-1 {
  width: 80%;
}

.section-select-2 {
  width: 20%;
}

.flex-delivery-create > button {
  width: -webkit-max-content;
  width: max-content;
}

.flex-delivery-create > button:hover {
  background-color: #d6d7d8;
}

.change-align {
  position: absolute;
  bottom: 0;
}

.error {
  color: red;
  font-size: 12px;
  font-weight: 600;
  display: none;
}

.full-width-field > select {
  width: 100%;
}

.flex-datetime-row {
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  width: 100%;
}

.flex-datetime-row > div {
  width: 49%;
}

.custom-size {
  width: 100%;
  overflow: hidden;
}

.signature-container {
  width: 100%;
}

.renLocationSelect {
  width: 100%;
}

@media screen and (max-width: 992px) {
  .flex-body {
    flex-wrap: wrap;
  }

  .flex-body > button {
    width: 100%;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1280px) {
  .rental-container {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
}

.background-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  border: 10px solid #f3f3f3;
  border-top: 10px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
          animation: spin 2s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -50px;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.flex-position-footer {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  justify-content: flex-end;
}

.flex-position-footer > button {
  width: -webkit-max-content;
  width: max-content;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 1rem;
  overflow: hidden;
}

.flex-position-footer {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  justify-content: flex-end;
}

.flex-position-footer > button {
  width: -webkit-max-content;
  width: max-content;
}

#addressTextArea {
  white-space: pre;
}

.textarea {
  background-color: #dddddd;
  color: #666666;
  padding: 1em;
  border-radius: 10px;
  border: 2px solid transparent;
  outline: none;
  font-family: "Heebo", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
  transition: all 0.2s;
}

.textarea:hover {
  cursor: pointer;
  background-color: #eeeeee;
}

.textarea:focus {
  cursor: text;
  color: #333333;
  background-color: white;
  border-color: #333333;
}

.grid-container {
  display: grid;
  grid-template-columns: 1.5fr;
  grid-template-rows: 1fr;
  grid-gap: 1rem;
  gap: 1rem;
  grid-auto-flow: row;
  grid-template-areas: "fields";
}

.fields {
  grid-area: fields;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-template-rows: 1fr;
  grid-gap: 1rem;
  gap: 1rem;
  grid-auto-flow: row;
}

@media screen and (max-width: 321px) {
  .grid-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-gap: 1rem;
    gap: 1rem;
    grid-auto-flow: row;
  }

  .fields {
    grid-area: fields;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(95px, 1fr));
    grid-template-rows: 1fr;
    grid-gap: 1rem;
    grid-gap: 1rem;
    gap: 1rem;
    grid-auto-flow: row;
  }
}

@media (min-width: 322px) and (max-width: 420px) {
  .grid-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-gap: 1rem;
    gap: 1rem;
    grid-auto-flow: row;
  }

  .fields {
    grid-area: fields;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    grid-template-rows: 1fr;
    grid-gap: 1rem;
    grid-gap: 1rem;
    gap: 1rem;
    grid-auto-flow: row;
  }
}

@media (min-width: 421px) and (max-width: 991px) {
  .grid-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-gap: 1rem;
    gap: 1rem;
    grid-auto-flow: row;
  }

  .fields {
    grid-area: fields;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-template-rows: 1fr;
    grid-gap: 1rem;
    grid-gap: 1rem;
    gap: 1rem;
    grid-auto-flow: row;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .fields {
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
  }
}

.header_modal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #e7e8e9;
}

.header_modal > button {
  width: -webkit-max-content;
  width: max-content;
  all: unset;
}

.btn_scanner {
  background-color: var(--prep-blue-background-color);
  color: var(--prep-white-color);
  border: none;
  outline: none;
  padding: 4px 10px;
  border-radius: 5px;
  color: white;
  width: -webkit-max-content;
  width: max-content;
  margin: 0 10px;
  transition: all 0.3s;
}

#scanner-container {
  position: relative;
}

#scanner-container > video {
  width: 100%;
  height: 100%;
}

#scanner-container > canvas.drawing,
canvas.drawingBuffer {
  position: absolute;
  left: 0;
  top: 0;
}

.table_container {
  margin: auto;
  padding: 20px;
}

.floating-container {
  position: fixed;
  width: 100px;
  bottom: 0;
  right: 0;
  margin: 20px 0px;
  z-index: 999;
}

.floating-container:hover {
  height: 300px;
}

.floating-container:hover .floating-button {
  background: var(--prep-done-or-not-available-color);
}

.floating-container .floating-button {
  color: #fff;
  position: absolute;
  width: 50px;
  height: 50px;
  background: #2cb3f0;
  bottom: 0;
  border: none;
  border-radius: 50%;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 999;
  cursor: pointer;
  transition: all 0.3s;
  background-color: var(--prep-blue-background-color);
}

.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

body {
  overflow: hidden;
}

.container_wheel {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins", sans-serif;
  position: relative;
  left: 6vmin;
  text-align: center;
}

.cog-wheel1,
.cog-wheel2 {
  -webkit-transform: scale(0.7);
          transform: scale(0.7);
}

.cog1,
.cog2 {
  width: 40vmin;
  height: 40vmin;
  border-radius: 50%;
  border: 6vmin solid #f3c623;
  position: relative;
}

.cog2 {
  border: 6vmin solid #4f8a8b;
}

.top,
.down,
.left,
.right,
.left-top,
.left-down,
.right-top,
.right-down {
  width: 10vmin;
  height: 10vmin;
  background-color: #f3c623;
  position: absolute;
}

.cog2 .top,
.cog2 .down,
.cog2 .left,
.cog2 .right,
.cog2 .left-top,
.cog2 .left-down,
.cog2 .right-top,
.cog2 .right-down {
  background-color: #4f8a8b;
}

.top {
  top: -14vmin;
  left: 9vmin;
}

.down {
  bottom: -14vmin;
  left: 9vmin;
}

.left {
  left: -14vmin;
  top: 9vmin;
}

.right {
  right: -14vmin;
  top: 9vmin;
}

.left-top {
  -webkit-transform: rotateZ(-45deg);
          transform: rotateZ(-45deg);
  left: -8vmin;
  top: -8vmin;
}

.left-down {
  -webkit-transform: rotateZ(45deg);
          transform: rotateZ(45deg);
  left: -8vmin;
  top: 25vmin;
}

.right-top {
  -webkit-transform: rotateZ(45deg);
          transform: rotateZ(45deg);
  right: -8vmin;
  top: -8vmin;
}

.right-down {
  -webkit-transform: rotateZ(-45deg);
          transform: rotateZ(-45deg);
  right: -8vmin;
  top: 25vmin;
}

.cog2 {
  position: relative;
  left: -10.2vmin;
  bottom: 10vmin;
}

.first-four {
  position: relative;
  left: 6vmin;
  font-size: 40vmin;
}

.second-four {
  color: #142833;
  position: relative;
  right: 18vmin;
  z-index: -1;
  font-size: 40vmin;
}

.wrong-para {
  font-family: "Montserrat", sans-serif;
  position: absolute;
  bottom: 15vmin;
  padding: 3vmin 12vmin 3vmin 3vmin;
  font-weight: 600;
  font-size: 4vmin;
  color: #092532;
}

#signatureName {
  background: powderblue;
}

.color-separate {
  background-color: #d9dfeb;
}

#showFiles,
#showCamera {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
  width: 100%;
  margin-bottom: 15px;
  transition: all 0.5s ease;
}

.hover_camera_btn:hover {
  background-color: lightcyan;
}

video {
  display: block;
}

.upload-container {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.upload-container label {
  display: block;
  cursor: pointer;
}

.opening_camera_buttons {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
  width: 100%;
  margin-bottom: 15px;
  transition: all 0.5s ease;
  outline: none;
  border: none;
  padding: 0.3rem;
}

.upload-container:hover,
.opening_camera_buttons:hover {
  background-color: var(--prep-secondary-background-color);
  color: black;
}

#file-input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
}

.card-body {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row !important;
  justify-content: center;
  align-items: center;
  grid-gap: 2rem;
  gap: 2rem;
}

#camera-select {
  border: none;
  border-bottom: 1px solid lightgray;
  outline: none;
  cursor: pointer;
}

.camera_controls_style {
  border: none;
  padding: 0.3rem 1rem;
  width: 100%;
  background-color: var(--prep-blue-background-color);
  color: white;
  border-radius: 5px;
  margin: 1rem auto;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  transition: all 0.2s ease-in-out;
}

.container_gallery_controls {
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.camera_controls_style:hover {
  background-color: var(--prep-secondary-background-color);
  color: black;
}

.delete_button {
  background-color: var(--prep-red-background-color);
}

.camera_dynamic_width {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.camera_gallery_controls {
  width: 350px;
  height: 300px;
}

.accordion-button.collapsed {
  border-color: #ced4da;
}

.childs_container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 1rem;
}

.parents_container {
  margin: 1rem;
  padding: 1rem;
}

.no-data-message {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f2f2f2;
  padding: 20px;
  border-radius: 4px;
  opacity: 0.9;
}

.emoji {
  font-size: 60px;
}

.parents_accordions {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 1rem;
  height: 20vh;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 1rem;
}

.accordion_button {
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding: 0.2rem 0.4rem;
  cursor: pointer;
  width: 100%;
  margin-top: 1rem;
  border-radius: 0.5rem;
  transition: 1s all;
}

.accordion_button:hover {
  background-color: #f2f2f2;
}

.parent_header {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  align-items: center;
  padding: 0 1rem;
}

.custom_width_parent_button {
  max-width: -webkit-max-content;
  max-width: max-content;
}

.custom-modal {
  max-width: 1000px;
  width: 90%;
}

.container_inputs_parent {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 1rem;
}

.container_flex_inputs {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
}

.btn_create_item {
  border-radius: 6px;
  padding: 5px;
  color: white;
  background-color: var(--prep-blue-background-color);
  border: none;
  outline: none;
}

@media screen and (max-width: 768px) {
  /* Estilos específicos para pantallas más pequeñas */
  .custom-modal {
    max-width: 90%; /* Disminuye el ancho máximo en pantallas más pequeñas */
  }

  .container_flex_inputs {
    flex-wrap: wrap;
  }
}

.container_grid_info_opp {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-gap: 1rem;
  gap: 1rem;
  padding: 1rem;
}

.title_banner {
  background-color: #f5f5f5;
  border-radius: 5px;
  margin-bottom: 10px;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 0px 5px 0px #e0e0e0;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
  font-weight: 600;
  color: #000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.flex_info_opp {
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.container_time_tracking {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
  padding: 1rem;
}

.form_input_separator {
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.form_input_separator > div {
  width: 100%;
}

.full-width {
  width: 100%;
}

.customModalTimeTracking {
  max-width: 700px;
  width: 100%;
}

.notification-container {
  position: fixed;
  width: 100px;
  bottom: 65px;
  right: 0;
  margin: 20px 0px;
  z-index: 999;
}

.notification-container:hover .floating-notification {
  background: var(--prep-done-or-not-available-color);
}

.notification-container .floating-notification {
  color: #fff;
  position: absolute;
  width: 50px;
  height: 50px;
  background: #2cb3f0;
  bottom: 0;
  border: none;
  border-radius: 50%;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 999;
  cursor: pointer;
  transition: all 0.3s;
  background-color: var(--prep-blue-background-color);
}

.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.notification-badge {
  position: absolute;
  top: -8px;
  right: -8px;
  background-color: red;
  color: white;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.notification-box {
  background-color: white;
  position: absolute;
  top: -250px;
  right: 10px;
  width: 300px;
  height: 265px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  z-index: 998;
  opacity: 0;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
  transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;
  transition: opacity 0.3s, transform 0.3s, -webkit-transform 0.3s;
  border-radius: 5px;
  padding: 1.5rem 1rem;
  overflow: hidden;
}

.notification-box.active {
  opacity: 1; /* Mostrar */
  -webkit-transform: translateY(0);
          transform: translateY(0); /* Volver a la posición normal */
}

.container-body {
  overflow: auto;
  height: 100%;
}

.notification-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #eee;
  margin-bottom: 1rem;
}

.notification-item-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.notification-item-body {
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin-top: 0.3rem;
  text-align: start;
  word-break: break-word;
}

.chip {
  background-color: var(--prep-blue-background-color);
  color: white;
  padding: 0.1rem 0.8rem;
  border-radius: 0.8rem;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.3s;
}

.empty-notification {
  position: absolute;
  top: 60%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  text-align: center;
  opacity: 0.5;
  font-size: 100px;
}

